import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeControllerService {
  private basePath: string = 'assets/themes/';
  private defaultTheme: string = 'default';
  private themesList: string[] = ['default', 'dark'];

  constructor() {
    this.setDefaultTheme();
  }

  public setNewDefault(defaultTheme: string): void {
    this.defaultTheme = defaultTheme ? defaultTheme : this.defaultTheme;
    this.setDefaultTheme();
  }

  public setDefaultTheme(): void {
    const elem = document.createElement('link');
    elem.rel = 'stylesheet';
    elem.type = 'text/css';
    elem.href = this.basePath + this.defaultTheme + '.scss';
    elem.setAttribute('id', 'theme');
    document.head.appendChild(elem);
  }

  public getThemesList(): string[] {
    return this.themesList;
  }

  public changeTheme(theme: string): void {
    const elem = document.getElementById('theme');
    elem.setAttribute('href', this.basePath + theme + '.scss');
  }
}
